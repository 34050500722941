<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-lg-12">
            <div class="d-flex flex-wrap flex-wrap align-items-center justify-content-between mb-4">
               <div>
                     <h4 class="mb-3">{{title}}</h4>
                     <p class="mb-0">{{desc1}}<br> {{desc2}}</p>
               </div>
               <!-- <router-link :to="link" class="btn btn-primary add-list"><i class="las la-plus mr-3"></i>{{linktext}}</router-link> -->
            </div>
         </div>
            <card>
               <div class="p-3">
                  <form  @submit.prevent="submit" >
                        <div class="row">
                                                         
                           <!-- <div class="col-md-12">
                              <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadio6" name="customRadio-2" class="custom-control-input" v-model="selectinfo" value="total" @change="changesearch">
                                    <label class="custom-control-label" for="customRadio6">전체</label>
                              </div>
                              <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadio7" name="customRadio-2" class="custom-control-input" v-model="selectinfo" value="stock" @change="changesearch">
                                    <label class="custom-control-label" for="customRadio7">재고</label>
                              </div>
                              <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadio8" name="customRadio-2" class="custom-control-input" v-model="selectinfo" value="Astock" @change="changesearch">
                                    <label class="custom-control-label" for="customRadio8">배치재고</label>
                              </div>
                              <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadio9" name="customRadio-2" class="custom-control-input" v-model="selectinfo" value="Pstock" @change="changesearch">
                                    <label class="custom-control-label" for="customRadio9">구매재고</label>
                              </div>
                              <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadio10" name="customRadio-2" class="custom-control-input" v-model="selectinfo" value="Rstock" @change="changesearch">
                                    <label class="custom-control-label" for="customRadio10">회송재고</label>
                              </div>                              
                           </div> -->
                           <!-- <div class="col-md-12 mt-2" v-if="showstock || showastock">
                              <b-input-group>
                                    <select class="custom-select col-md-2" style="height:40px" v-model="selectoption">
                                       <option>입고일시</option>
                                       <option v-if="showastock">배치일시</option>
                                       <option v-if="showastock">입고예정일</option>
                                    </select>
                                    <b-form-datepicker id="example-datepicker1" size="sm" v-model="startdate" class="mb-2"></b-form-datepicker>
                                    <p>~</p>
                                    <b-form-datepicker id="example-datepicker2" size="sm" v-model="enddate" class="mb-2"></b-form-datepicker>
                              </b-input-group>
                           </div>                            -->
                           <!-- <div class="col-md-3 mt-2" v-if="showstock || showastock">
                              <div class="form-group">
                                    <input type="text" class="form-control" placeholder="박스번호" v-model="박스번호">
                              </div>
                           </div> 
                           <div class="col-md-3 mt-2" v-if="showstock || showastock">
                              <div class="form-group">
                                    <input type="text" class="form-control" placeholder="박스위치" v-model="파렛트번호">
                              </div>
                           </div>

                           <div class="col-md-3 mt-2">
                              <div class="form-group">
                                    <input type="text" class="form-control" placeholder="바코드" v-model="바코드">
                              </div>
                           </div> 
                           <div class="col-md-3 mt-2">
                              <div class="form-group">
                                    <input type="text" class="form-control" placeholder="SKU ID" v-model="SKUID">
                              </div>
                           </div>
                           <div class="col-md-3 mt-2" v-if="showastock">
                              <div class="form-group">
                                    <input type="text" class="form-control" placeholder="발주번호" v-model="발주번호">
                              </div>
                           </div>
                           <div class="col-md-3 mt-2" v-if="showastock">
                              <div class="form-group">
                                    <input type="text" class="form-control" placeholder="발주센터" v-model="발주센터">
                              </div>
                           </div>                           
                           <div class="col-md-3 mt-2" v-if="showpstock || showrstock">
                              <div class="form-group">
                                    <input type="text" class="form-control" placeholder="입고송장번호" v-model="입고송장번호">
                              </div>
                           </div> -->



                           <div class="col-md-3 mt-2">
                              <div class="form-group">
                                    <input type="text" class="form-control" placeholder="재고박스번호" v-model="재고박스번호">
                              </div>
                           </div> 
                           <div class="col-md-3 mt-2">
                              <div class="form-group">
                                 <input type="text" class="form-control" placeholder="발주배치박스번호" v-model="배치박스번호">
                              </div>
                           </div> 
                           <div class="col-md-3 mt-2">
                              <div class="form-group">
                                    <input type="text" class="form-control" placeholder="박스위치" v-model="파렛트번호">
                              </div>
                           </div>

                           <div class="col-md-3 mt-2">
                              <div class="form-group">
                                    <input type="text" class="form-control" placeholder="바코드" v-model="바코드">
                              </div>
                           </div> 
                           <div class="col-md-3 mt-2">
                              <div class="form-group">
                                    <input type="text" class="form-control" placeholder="SKU ID" v-model="SKUID">
                              </div>
                           </div>
                           <div class="col-md-3 mt-2">
                              <div class="form-group">
                                    <input type="text" class="form-control" placeholder="발주번호" v-model="발주번호">
                              </div>
                           </div>
                           <div class="col-md-3 mt-2">
                              <div class="form-group">
                                    <input type="text" class="form-control" placeholder="발주센터" v-model="발주센터">
                              </div>
                           </div>                           
                           <div class="col-md-3 mt-2">
                              <div class="form-group">
                                    <input type="text" class="form-control" placeholder="입고송장번호" v-model="입고송장번호">
                              </div>
                           </div>


                                                               
                        </div>                            
                        <button type="button" class="btn btn-primary mr-2 mt-1" @click="searchdata">검색</button>
                        <div class="row" v-if="showspinner">
                           <div class="col-md-3 text-center">
                              <img style="width:20%;" :src="require('../../../assets/images/small/loading.gif')">
                           </div>
                        </div>
                  </form>
               </div>
            </card>

            <div class="col-lg-12 mb-4" v-if="showlist">
               <div class="row">
                   <b-button variant="outline-primary" class="mb-1 mr-2" @click="showmototal">
                  전체 <b-badge variant="primary" class="ml-2">{{mototal}}</b-badge>
                  </b-button>
                  <b-button variant="outline-warning" class="mb-1 mr-2" @click="showmostock">
                  재고<b-badge variant="warning" class="ml-2">{{mostock}}</b-badge>
                  </b-button>
                  <b-button variant="outline-success" class="mb-1 mr-2" @click="showmoastock">
                  배치재고<b-badge variant="success" class="ml-2">{{moastock}}</b-badge>
                  </b-button>
                  <!-- <b-button variant="outline-danger" class="mb-1 mr-2" @click="showmopstock">
                  구매입고예정<b-badge variant="danger" class="ml-2">{{mopstock}}</b-badge>
                  </b-button>
                  <b-button variant="outline-warning" class="mb-1 mr-2" @click="showmorstock">
                  회송입고예정<b-badge variant="warning" class="ml-2">{{morstock}}</b-badge>
                  </b-button>                                                                                     -->
               </div> 
            </div>


			<div class="col-lg-12" v-if="showlist">
				<div class="table-responsive rounded mb-3">
					<tableList>
						<template v-slot:header>
							<tr class="ligth ligth-data">
								<th>
									<div class="checkbox d-inline-block">
										<input type="checkbox" class="checkbox-input" id="checkbox1" @change="checkallbox" v-model="checkbox1">
										<label for="checkbox1" class="mb-0"></label>
									</div>
								</th>
                        <th>구분</th>
                        <th>박스번호</th>
                        <th>박스위치</th>
								<th>상품정보</th>
								<th>발주번호</th>
                        <th>발주센터</th>
                        <th>입고예정일</th>
								<th>입고일시</th>
                        <!-- <th>actions</th> -->
							</tr>
						</template>
						<template v-slot:body>
							<tr v-for="(stocklist,index) in stocklists" :key="index">
								<td>
									<div class="checkbox d-inline-block">
										<input type="checkbox" class="checkbox-input" id="checkbox2" v-model="stocklist.checked">
										<label for="checkbox2" class="mb-0"></label>
									</div>
								</td>
                        <td><div v-bind:class="badgecolor(stocklist.stockinfo)">{{changestockinfo(stocklist.stockinfo)}}</div></td>
                        <td>{{changeboxinfo(stocklist.stockinfo,stocklist.재고박스번호,stocklist.배치박스번호)}}</td>
                        <td>{{changepalletinfo(stocklist.stockinfo,stocklist.재고파렛트번호,stocklist.배치파렛트번호)}}</td>
                        <td style="min-width:300px;">
                           <div class="d-flex align-items-center">
                                 <img :src="stocklist.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                 <div>
                                 {{stocklist.상품명}}
                                 <p class="mb-0"><small>{{stocklist.바코드}}</small></p>
                                 <p class="mb-0"><small>{{stocklist.SKUID}}</small></p>
                                 </div>
                           </div>
                        </td>         
                           <b-modal :id="'modal'+index" size="xl" title="상품 상세 정보" ok-title="OK" cancel-title="Close">
                           <!-- <b-modal :id="'modal'+index" size="xl" title="상품 상세 정보" cancel-title="Close"> -->
                                 <p>상품명 : {{stocklist.상품명}}<br>SKU ID : {{stocklist.SKUID}}<br>바코드 : {{stocklist.바코드}}</p>
                                 
                                 <img :src="stocklist.등록이미지" style="width:50%;display: block; margin: 0px auto; border:1px solid silver; border-radius: 30px">
                                 
                           </b-modal>       
								<td>{{stocklist.배치발주번호}}</td>
								<td>{{stocklist.배치발주센터}}</td>
                        <td>{{stocklist.배치입고예정일}}</td>                                   
								<td>{{stocklist.입고일시}}</td>

								<!-- <td>
									<div class="d-flex align-items-center list-action"> 
                              <a class="badge badge-info mr-2" data-toggle="tooltip" v-b-tooltip.top title="View" href="javascript:void(0)" v-b-modal="modalID(index)"><i class="ri-eye-line mr-0"></i></a> 
                              <a class="badge bg-warning mr-2" data-toggle="tooltip" v-b-tooltip.top title="Delete" href="javascript:void(0)" @click="deletestockone(index)"><i class="ri-delete-bin-line mr-0"></i></a> 
                           </div>
								</td> -->
							</tr>
						</template>
					</tableList>
				</div>
            <button type="button" class="btn btn-primary mr-2 mb-4" @click="download">다운로드</button>
            <!-- <button type="button" class="btn btn-secondary mr-2 mb-4" @click="deletestock">삭제</button> -->
            <div class="float-right"><input type="text" class="form-control" placeholder="Page검색" v-model="pagenumber" @keyup.enter="pagemv($event)"></div>
			</div>
		</div>
		<!-- Page end  -->
	</div>
</template>
<script>
// import { mapState } from 'vuex';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { core } from '../../../config/pluginInit'
export default {
    name:'Logis',
    data(){
        return{
            title:"배송대행현황",
            desc1:"재고(현재고), 배치재고(현재고)",
            desc2:"재고 : 현재 남아있는 재고(출고배치가 되지 않음) / 배치재고 : 출고배치가 완료된 재고",
            // link:{ name: 'sale.addsale'},
            // linktext:"재고등록",
            badge : 'badge',
            // selectinfo: 'total',

            showspinner: false,

            selectoption: '입고일시',
            startdate: this.getstartdate(),
            enddate: this.getTodaywith(),
            재고박스번호: '',
            배치박스번호: '',
            파렛트번호: '',
            바코드: '',
            SKUID: '',
            발주번호: '',
            발주센터: '',
            입고송장번호: '',

            checkbox1:false,

            showstock: false,
            showastock: false,
            // showpstock: false,
            // showrstock: false,            
            stocklists : [],
            stocklistsinit : [],
            showlist: false,

            notarrangedcnt: 0,

            //배치박스(boxlist 정보)
            qty: [],
            boxnumber: [],
            palletnumber: [],
            
            mototal: 0,
            mostock: 0,
            moastock: 0,
            // mopstock: 0,
            // morstock: 0,

            pagenumber: '',
        }
    },
    mounted(){
      // this.search();
    },	
    methods : {
      currentpage(){
         document.getElementsByClassName('paginate_button')
      },
      pagemv(event){
         if(event.keyCode === 13){
            core.mvpage(this.pagenumber)
			}
      },
      showmototal(){
         this.showlist = false;
         this.stocklists = this.stocklistsinit;
         setTimeout(() => {this.showlist = true;},10)
         
      },
      showmostock(){
         this.showlist = false;
         this.stocklists = this.stocklistsinit.filter(e => e.stockinfo == 'stock')
         setTimeout(() => {this.showlist = true;},10)
      },
      showmoastock(){
         this.showlist = false;
         this.stocklists = this.stocklistsinit.filter(e => e.stockinfo == 'Astock')
         setTimeout(() => {this.showlist = true;},10)
      },
      // showmopstock(){
      //    this.stocklists = this.stocklistsinit.filter(e => e.stockinfo == 'Pstock')
      //    this.showlist = true;
      // },
      // showmorstock(){
      //    this.stocklists = this.stocklistsinit.filter(e => e.stockinfo == 'Rstock')
      //    this.showlist = true;
      // },
      // changesearch(){
      //    if(this.selectinfo == 'total'){            
      //       this.showstock = false;
      //       this.showastock = false;
      //       this.showpstock = false;
      //       this.showrstock = false;
      //    } else {
      //       if(this.selectinfo == 'stock'){
      //          this.showstock = true;
      //          this.showastock = false;
      //          this.showpstock = false;
      //          this.showrstock = false;
      //       } else {
      //          if(this.selectinfo == 'Astock'){
      //             this.showstock = false;
      //             this.showastock = true;
      //             this.showpstock = false;
      //             this.showrstock = false;
      //          } else {
      //             if(this.selectinfo == 'Pstock'){
      //                this.showstock = false;
      //                this.showastock = false;
      //                this.showpstock = true;
      //                this.showrstock = false;
      //             } else {
      //                if(this.selectinfo == 'Rstock'){
      //                   this.showstock = false;
      //                   this.showastock = false;
      //                   this.showpstock = false;
      //                   this.showrstock = true;
      //                }
      //             }
      //          }
      //       }
      //    }
      // },
      checkallbox(){
         if(this.checkbox1){
            this.stocklists.forEach(e => e.checked = true)
         } else {
            this.stocklists.forEach(e => e.checked = false)
         }
      },
      deletestock(){
         if(this.stocklists.filter(e => e.checked).length == 0){
            alert("삭제할 제품을 선택해 주세요")
            return
         }
			if(confirm("선택하신 재고를 삭제하시겠습니까?") == true){
				this.showlist = false;
				var dellist = this.stocklists.filter(e => e.checked);
            
            this.stocklistsinit = this.stocklistsinit.filter(e => dellist.filter(ele => ele.id == e.id).length == 0);
				this.stocklists = this.stocklists.filter(e => !e.checked);
				setTimeout(() => {
					this.deletestockdb(dellist)
					this.$store.commit('stockdelete',dellist);
					this.showlist = true;
				}, 1);

			} else {
				return false
			}

		},
      deletestockone(index){
         console.log(this.stocklists)
         if(confirm("해당 재고를 삭제하시겠습니까?") == true){
            var arr = [];
            arr.push(this.stocklists[index]);
            this.deletestockdb(arr)
            this.$store.commit('stockdelete',arr);
            this.stocklists = this.deletelist(this.stocklists,index)
         }
      },
      deletestockdb(arr){
			axios.post('/api/deletestock',arr)
			.then((res) => {
				if(res.data.요청결과 == "삭제완료"){
					console.log(res.data)
				} else {
					alert("삭제실패")
					console.log(res.data)
               return false
				}
			})	
			.catch((e) => {alert("업데이트 실패");console.log(e);return false})
		},
      deletelist(array,i){
         return array.slice(0,i).concat(array.slice(i+1,array.length))
      },
      async searchdata(){
         this.search();
      },
      async search(){
         this.showspinner = true;
         this.showlist = false;
         this.stocklists = [];
         this.stocklistsinit = [];
         var arr = [];
         
         if(this.$store.state.stock.filter(e => e.site == 'cn').length > 0){
            arr = this.$store.state.stock.filter(e => e.site == 'cn');
            if(this.재고박스번호.length > 0){
               arr = arr.filter(e => e.재고박스번호 == this.재고박스번호)
            }
            if(this.배치박스번호.length > 0){
               arr = arr.filter(e => e.배치박스번호 == this.배치박스번호)
            }
            if(this.파렛트번호.length > 0){
               arr = arr.filter(e => e.재고파렛트번호 == this.파렛트번호 || e.배치파렛트번호 == this.파렛트번호)
            }
            if(this.바코드.length > 0){
               arr = arr.filter(e => e.바코드 == this.바코드)
            }
            if(this.SKUID.length > 0){
               arr = arr.filter(e => e.SKUID == this.SKUID)
            }
            if(this.발주번호.length > 0){
               arr = arr.filter(e => e.배치발주번호 == this.발주번호)
            }
            if(this.발주센터.length > 0){
               arr = arr.filter(e => e.배치발주센터 == this.발주센터)
            }                              
            if(this.입고송장번호.length > 0){
               arr = arr.filter(e => e.입고송장번호 == this.입고송장번호)
            }               
            arr.forEach(e => {
               e.checked = false;
               this.$store.state.productdb.some(ele => {
                  if(ele.바코드 == e.바코드){
                     e.등록이미지 = ele.등록이미지;
                     return (ele.바코드 == e.바코드)
                  }
               })
            })

            arr.sort(function(a,b){
               if (a.입고일시 < b.입고일시){
                  return -1;
               }
               if (a.입고일시 > b.입고일시){
                  return 1;
               }
               return 0;
            })

            this.stocklists = arr;
            this.stocklistsinit = arr;
            this.mototal = this.stocklistsinit.length;
            this.mostock = this.stocklistsinit.filter(e => e.stockinfo == 'stock').length;
            this.moastock = this.stocklistsinit.filter(e => e.stockinfo == 'Astock').length;
            this.mopstock = this.stocklistsinit.filter(e => e.stockinfo == 'Pstock').length;
            this.morstock = this.stocklistsinit.filter(e => e.stockinfo == 'Rstock').length;
            // console.log({showliststatus:true})

            
            setTimeout(() => {
               this.showspinner = false;
               this.showlist = true;               
            }, 50);
         } else {
            alert("재고리스트가 없습니다.")
            setTimeout(() => {
               this.showspinner = false;
               this.showlist = false;               
            }, 50);
         }
      },
      openfindlist(barcode){
			var arrangecnt = 0;
			var notarrangecnt = 0;
			var pstockcnt = 0;
			var rstockcnt = 0;
			const bar = barcode;
			if(this.$store.state.productdb.find(e => e.바코드 == bar) === undefined){
				alert("존재하지 않는 바코드 입니다")
			} else {
				
					//바코드 찾는 함수
					var result = [];
					var temp = {};
					this.$store.state.polist.forEach((e) => {
						if(!e.발주상태.includes("거래명세서")) {
							e.발주세부내역.some((ele) => {
								if(ele.바코드 == bar){
									arrangecnt = 0;
									notarrangecnt = 0;
									pstockcnt = 0;
									rstockcnt = 0;
									if (this.$store.state.stock.length != 0){
										this.$store.state.stock.forEach(element => {
											if(element.바코드 == ele.바코드){
												if(element.배치발주번호 == e.발주번호){
													arrangecnt++
												}
											}
										})
									}
									ele.발주수량 = parseInt(ele.발주수량);
									ele.입고수량 = parseInt(ele.입고수량);
									if(isNaN(ele.입고수량)){
										ele.입고수량 = 0;
									}
									ele.확정수량 = parseInt(ele.확정수량);
									ele.배치수량 = arrangecnt;
									ele.미배치재고수량 = notarrangecnt;
									ele.구매완료수량 = pstockcnt;
									ele.회송예정수량 = rstockcnt;
									ele.사입재고수량 = 0;
									if(parseInt(ele.확정수량) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt > 0){
										this.$store.state.productdb.some(element => {
											if(ele.바코드 == element.바코드 && element.구매링크 == '사입'){
												ele.사입재고수량 = parseInt(ele.확정수량) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt;
												return (ele.바코드 == element.바코드 && element.구매링크 == '사입')
											}
										})
									}
									ele.구매필요수량 = parseInt(ele.확정수량) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt - ele.사입재고수량;
									ele.필요수량 = ele.확정수량 - ele.배치수량


									if(ele.필요수량 > 0){
										temp = this.deepClone(e);
										temp.발주세부내역 = ele;
										result.push(temp);
										temp = {};
										return (ele.바코드 == bar && ele.필요수량 > 0)
									}
								}
							});
						}
					})
					//발주센터로 먼저 정렬
					result.sort(function(a,b){
						if (a.발주센터 < b.발주센터){
							return -1;
						}
						if (a.발주센터 > b.발주센터){
							return 1;
						}
						return 0;
					})
					//입고예정일순으로 최종 정렬
					result.sort(function(a,b){
						return new Date(a.입고예정일) - new Date(b.입고예정일);
					})
					console.log(result)
               
               var num
					result.forEach((a,i) => {
						this.qty[i] = 0;
						num = this.$store.state.stock.filter(e => e.site == 'cn').filter(e => e.배치발주센터 == a.발주센터 && e.배치입고예정일 == a.입고예정일).reduce((acc, shot) => acc = acc > shot.배치박스번호 ? acc : shot.배치박스번호, 0);
						console.log(num)
						if (num == 0 || isNaN(num)){
							var newone = parseInt(this.maxvalue(this.$store.state.stock.filter(e => e.site == 'cn'),'배치박스번호')) + 1;
							console.log(newone)
							this.boxnumber.push(parseInt(newone));
							this.palletnumber.push('');
						} else {
							this.boxnumber.push(parseInt(num));
							this.palletnumber.push(this.$store.state.stock.filter(e => e.site == 'cn').find(e => e.배치박스번호 == num).배치파렛트번호);
						}
						
					})

					if(result.length > 0){
						// 발주번호, 발주센터, 입고예정일, badge(배치필요) 추가 -------------------------------------------------------------->수정중
					}
				
			}
		},    
      maxvalue(data,key){
			return data.reduce((prev, current) => (prev[key] > current[key]) ? prev : current)[key]
		},  
      getTodaywith(){
         var date = new Date();
         var year = date.getFullYear();
         var month = ("0" + (1 + date.getMonth())).slice(-2);
         var day = ("0" + date.getDate()).slice(-2);

         return year + "-" + month + "-" + day;
      },  
      getstartdate(){
         var date = new Date();
         var year = date.getFullYear()-1;
         var month = ("0" + (1 + date.getMonth())).slice(-2);
         var day = ("0" + date.getDate()).slice(-2);

         return year + "-" + month + "-" + day;
      },        
      modalID(index) {
         return 'modal' + index
      },
      modalIDedit(index) {
         return 'modaledit' + index
      },      
      deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}
			const result = Array.isArray(obj) ? [] : {}
			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}
			return result
      },
      changestockinfo(stockinfo){
         if(stockinfo == "stock") {
            return "재고"
         }
         if(stockinfo == "Astock") {
            return "배치재고"
         }
         if(stockinfo == "Pstock") {
            return "구매재고"
         }
         if(stockinfo == "Rstock") {
            return "회송재고"
         }                      
      },
      changeboxinfo(stockinfo,재고박스번호,배치박스번호){
         if(stockinfo == "stock") {
            return 재고박스번호
         }
         if(stockinfo == "Astock") {
            return 배치박스번호
         }
         if(stockinfo == "Pstock") {
            return "구매입고전"
         }
         if(stockinfo == "Rstock") {
            return "회송입고전"
         } 
      },
      changepalletinfo(stockinfo,재고파렛트번호,배치파렛트번호) {
         if(stockinfo == "stock") {
            return 재고파렛트번호
         }
         if(stockinfo == "Astock") {
            return 배치파렛트번호
         }
         if(stockinfo == "Pstock") {
            return "구매입고전"
         }
         if(stockinfo == "Rstock") {
            return "회송입고전"
         }            
      },
      badgecolor(stockinfo){
         if(stockinfo == "stock") {
            return "badge badge-primary"
         }
         if(stockinfo == "Astock") {
            return "badge badge-success"
         }
         if(stockinfo == "Pstock") {
            return "badge badge-secondary"
         }
         if(stockinfo == "Rstock") {
            return "badge badge-danger"
         } 
      },
      timestamp(date){
         //inputdate = "yyyy-mm-dd"
         return new Date(date).getTime() / 1000
      },
      download() {
         var arr = [];
         if(this.stocklists.filter(e => e.checked).length == 0){
            arr = this.stocklists
         } else {
            arr = this.stocklists.filter(e => e.checked)
         }
         function getToday(){
               var date = new Date();
               var year = date.getFullYear();
               var month = ("0" + (1 + date.getMonth())).slice(-2);
               var day = ("0" + date.getDate()).slice(-2);

               return year + month + day;
         }

         const workBook = XLSX.utils.book_new()
         const workSheet1 = XLSX.utils.json_to_sheet(arr)
         XLSX.utils.book_append_sheet(workBook, workSheet1, '재고현황')
         XLSX.writeFile(workBook, '재고현황' + '_' + getToday() + '.xlsx')
      }
    }
}
</script>
<style scoped>
.modal-dialog {
    max-height: 90%;
    position: absolute; 
    
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
</style>
